import { useGetRequest } from "../../Client";
import LeaderDropdown from "./Components/LeaderDropdown";
import { use, useState, useEffect } from "react";

export default function Form(props) {
  const [initialFees, setInitialFees] = useState(false);
  const [rebate, setRebate] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    props.setFormInputs((values) => ({
      ...values,
      [props.item + "--" + name]: value,
    }));
  };

  const { status: statusPortfolios, data: dataPortfolios } = useGetRequest(
    "portfolios/" + props.companyID,
    {}
  );

  const { status: statusStrategies, data: dataStrategies } = useGetRequest(
    "strategies/" + props.companyID,
    {}
  );

  const { status: statusProviders, data: dataProviders } = useGetRequest(
    "providers",
    {}
  );

  if (
    statusPortfolios == "fetched" &&
    statusStrategies == "fetched" &&
    statusProviders == "fetched"
  ) {
    return (
      <div className="p-2 border-2 border-slate-100 rounded-xl md:max-xl:flex lg:w-full">
        <div>
          <div className="flex flex-row justify-between">
            <div>
              <h2 class="pl-2 mb-2 text-xl font-bold text-gray-900 dark:text-white">
                {props.item}
              </h2>
            </div>
            {props.item !== "Strategy 1" ? (
              <div>
                <h2
                  className="text-xs font-semibold leading-7 text-red-600 px-2 hover:cursor-pointer hover:text-red-700"
                  onClick={() => {
                    props.areYouSure(props.item);
                  }}
                >
                  Delete strategy
                </h2>
              </div>
            ) : null}
          </div>

          <h5 class="px-2 block mb-2 text-sm font-medium text-gray-800 dark:text-white">
            Investment Information
          </h5>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="policy_number"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Policy Number
              </label>
              <input
                type="text"
                id="policy_number"
                name="policy_number"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="pension_investment"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Product
              </label>
              <select
                id="pension_investment"
                name="pension_investment"
                onChange={handleChange}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>

                {props?.inputs?.product == "WRAP" ? (
                  <>
                    <option value="Pension">Pension</option>
                    <option value="Investment">Investment</option>
                  </>
                ) : null}

                {props?.inputs?.product == "Insurance" ? (
                  <>
                    <option value="Insurance">Insurance</option>
                    <option value="Investment">Investment</option>
                  </>
                ) : null}

                {props?.inputs?.product == "Investment" ? (
                  <>
                    <option value="Investment">Investment</option>
                    <option value="Pension">Pension</option>
                  </>
                ) : null}

                {props?.inputs?.product == "Pension" ? (
                  <>
                    <option value="Insurance">Insurance</option>

                    <option value="Investment">Investment</option>
                    <option value="Pension">Pension</option>
                  </>
                ) : null}
              </select>
            </div>

            <div className="w-1/5 p-2">
              <label
                for="product_type"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Product Type
              </label>
              <select
                id="product_type"
                name="product_type"
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                onChange={handleChange}
              >
                <option value=""></option>

                {props.formInputs[props.item + "--pension_investment"] ==
                "Insurance" ? (
                  <>
                    <option value="Bond">Bond</option>
                    <option value="Term">Term</option>
                    <option value="Other">Other</option>
                  </>
                ) : null}

                {props.formInputs[props.item + "--pension_investment"] ==
                "Investment" ? (
                  <>
                    <option value="GIA">GIA</option>
                    <option value="Regular Savings">Regular Savings</option>
                    <option value="ISA">ISA</option>
                    <option value="DFM">DFM</option>
                    <option value="SIPP">SIPP</option>
                    <option value="Other">Other</option>
                  </>
                ) : null}

                {props.formInputs[props.item + "--pension_investment"] ==
                "Pension" ? (
                  <>
                    <option value="Insured Pension">Insured Pension</option>
                    <option value="SIPP">SIPP</option>
                    <option value="QROPS">QROPS</option>
                    <option value="Other">Other</option>
                  </>
                ) : null}
              </select>
            </div>

            <div className="w-1/5 p-2">
              <label
                for="trustee"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Trustee
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="trustee"
                id="trustee"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="trustee_no"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Trustee No
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="trustee_no"
                id="trustee_no"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 1
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="sub_policy_number_1"
                id="sub_policy_number_1"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 2
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="sub_policy_number_2"
                id="sub_policy_number_2"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_3"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 3
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="sub_policy_number_3"
                id="sub_policy_number_3"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-1/5 p-2">
              <label
                for="sub_policy_number_4"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Policy Number 4
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="sub_policy_number_4"
                id="sub_policy_number_4"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="dfm_servicing_company"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                DFM is Servicing Company
              </label>
              <select
                id="dfm_servicing_company"
                onChange={handleChange}
                name="dfm_servicing_company"
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <LeaderDropdown
              label={"Team Leader"}
              name={"team_leader"}
              companyID={props.companyID}
              handleChange={handleChange}
            />

            <div className="w-1/5 p-2">
              <label
                for="provider"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Provider
              </label>
              <select
                id="provider"
                name="provider"
                onChange={handleChange}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value={0}></option>
                {dataProviders.providers.map((provider) => (
                  <option value={provider.providerID}>{provider.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="invested_value"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Invested Value
              </label>
              <input
                type="text"
                id="invested_value"
                name="invested_value"
                onChange={handleChange}
                value={props.formInputs[props.item + "--invested_value"]}
                required  
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="currency"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Currency
              </label>
              <select
                id="currency"
                name="currency"
                defaultValue={"£"}
                onChange={handleChange}
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value="1">£</option>
                <option value="2">$</option>
                <option value="3">€</option>
                <option value="4">CZK</option>
              </select>
            </div>

            {props.formInputs[props.item + "--currency"] > 1 ? (
              <div className="w-1/5 p-2">
                <label
                  for="exchange_rate"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Exchange Rate
                </label>
                <input
                  type="text"
                  id="exchange_rate"
                  onChange={handleChange}
                  name="exchange_rate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            ) : null}
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="portfolio"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Portfolio
              </label>
              <select
                id="portfolio"
                name="portfolio"
                onChange={handleChange}
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option></option>
                {dataPortfolios?.portfolios?.map((portfolio) => (
                  <option value={portfolio.portfolioID}>
                    {portfolio.portfolioName}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-1/5 p-2">
              <label
                for="strategy_type"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Strategy Type
              </label>
              <select
                id="strategy_type"
                name="strategy_type"
                onChange={handleChange}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option></option>
                {dataStrategies?.strategies?.map((strategy) => (
                  <option value={strategy.strategyID}>
                    {strategy.strategyName}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-1/5 p-2">
              <label
                for="fees_paid_from"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Strategy Fees Paid From
              </label>
              <select
                id="fees_paid_from"
                name="fees_paid_from"
                onChange={handleChange}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>
                <option value="Servicing Company">Servicing Company</option>
                <option value="Seperate Disclosure">Seperate Disclosure</option>
                <option value="Adviser Declared Fee">
                  Adviser Declared Fee
                </option>
              </select>
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="strategy_fee"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Strategy Fee %
              </label>
              <input
                type="text"
                id="strategy_fee"
                onChange={handleChange}
                name="strategy_fee"
                value={props.formInputs[props.item + "--strategy_fee"]}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="advisor_charge"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Split / Adviser Charge (%)
              </label>
              <input
                type="text"
                id="advisor_charge"
                onChange={handleChange}
                name="advisor_charge"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="rebate"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Will We Need To Rebate?
              </label>
              <input
                type="checkbox"
                id="rebate"
                checked={rebate}
                value={1}
                onChange={() => setRebate(!rebate)}
                // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                className="align-center h-4"
              />
            </div>

            {rebate === true ? (
              <div className="w-1/5 p-2">
                <label
                  for="rebate_percentage"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  % Required To Rebate
                </label>
                <input
                  type="text"
                  id="rebate_percentage"
                  onChange={handleChange}
                  name="rebate_percentage"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            ) : null}
          </div>

          <h5 class="px-2 block mb-2 mt-4 text-sm font-medium text-gray-800 dark:text-white">
            Adviser Declared Fee or Commission
          </h5>

          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="ongoing_charge"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Ongoing % Charge
              </label>
              <input
                type="text"
                id="ongoing_charge"
                onChange={handleChange}
                name="ongoing_charge"
                value={props.formInputs[props.item + "--ongoing_charge"]}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="ongoing_fixed_charge"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Ongoing Fixed Charge
              </label>
              <input
                type="text"
                id="ongoing_fixed_charge"
                onChange={handleChange}
                name="ongoing_fixed_charge"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="unique_identifier"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Unique Identifier
              </label>
              <input
                type="text"
                id="unique_identifier"
                onChange={handleChange}
                name="unique_identifier"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <h5 class="px-2 block mb-2 mt-4 text-sm font-medium text-gray-800 dark:text-white">
            Initial Fee Options
          </h5>
          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="initial_fees"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Are there initial fees as well?
              </label>
              <input
                type="checkbox"
                id="initial_fees"
                checked={initialFees}
                onChange={() => setInitialFees(!initialFees)}
                className="align-center h-4"
              />
            </div>

            {initialFees === true ? (
              <>
                <div className="w-1/5 p-2">
                  <label
                    for="initial_charge"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Initial Charge (%)
                  </label>
                  <input
                    type="text"
                    id="initial_charge"
                    onChange={handleChange}
                    name="initial_charge"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="w-1/5 p-2">
                  <label
                    for="advisor_charge"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Split / Adviser Charge (%)
                  </label>
                  <input
                    type="text"
                    id="advisor_charge"
                    onChange={handleChange}
                    name="advisor_charge"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="w-1/5 p-2">
                  <label
                    for="fixed_charge"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Initial Fixed Charge (£)
                  </label>
                  <input
                    type="text"
                    id="fixed_charge"
                    onChange={handleChange}
                    name="fixed_charge"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
