import { useGetRequest } from "../../../../Client";

export default function TeamLeaderDropdown(props) {
  const { status: statusAdvisors, data: dataAdvisors } = useGetRequest(
    "people/dropdown/leaders/" + props.companyID,
    {}
  );

  if (statusAdvisors == "fetched") {
    return (
      <div className="w-1/5 p-2">
        <label
          for={props.name}
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {props.label}
        </label>
        <select
          id={props.name}
          name={props.name}
          onChange={(e) => {
            props.setTeamLeader(e.target.value);
          }}
          required
          className="pl-2 bg-gray-50 h-10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >

          {dataAdvisors?.people?.map((advisor) => (
            <option value={advisor.personID}>
              {advisor.surname}, {advisor.firstName}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
