import { use, useState, useEffect } from "react";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import AdvisorDropdown from "./Components/AdvisorDropdown";
import IntroducerDropdown from "./Components/IntroducerDropdown";
import SecondAdvisorDropdown from "./Components/SecondAdvisorDropdown";

export default function Headline(props) {
  const initialDate = new Date().toISOString().split("T")[0];
  const initialDateArray = initialDate.split("-");

  const [inputs, setInputs] = useState({
    date:
      initialDateArray[0] +
      "-" +
      initialDateArray[1] +
      "-" +
      initialDateArray[2],
  });

  const [currency, setCurrency] = useState("£");
  const [advisor, setAdvisor] = useState("");

  useEffect(() => {
    console.log(advisor);
  }, [advisor]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const { status: statusCompanies, data: dataCompanies } = useGetRequest(
    "companies",
    {}
  );

  if (statusCompanies == "fetched") {
    return (
      // <div className="flex flex-col mx-36 p-2 bg-white border-2 border-slate-100 rounded-xl">
      <div className="flex flex-col p-2 bg-white ">
        <div>
          <h2 class="mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Headline Information {advisor}
          </h2>
        </div>
        <div className="flex flex-row mt-4">
          <div className="w-1/5 p-2">
            <label
              for="company"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Regulated / Servicing Company
            </label>
            <select
              id="company"
              name="company"
              onChange={() => {
                props.setCompanyID(document.getElementById("company").value);
              }}
              required
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value={0}></option>
              {dataCompanies.companies.map((company) => (
                <option value={company.companyID}>{company.companyName}</option>
              ))}
            </select>
          </div>

          <AdvisorDropdown
            label={"Ultimate Owner"}
            name={"ultimate_owner"}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="start_date"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Start Date
            </label>
            <input
              type="date"
              id="start_date"
              name="date"
              value={inputs.date || ""}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="product"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Product
            </label>
            <input
              type="text"
              id="product"
              value="Insurance"
              className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              disabled
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="product_provider"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Provider
            </label>
            <select
              id="product_provider"
              name="product_provider"
              required
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              onChange={handleChange}
            >
              <option value=""></option>

              <>
                <option value="Friends Life">Friends Life</option>
                <option value="Friends Provident">Friends Provident</option>
                <option value="Generali/Utmost WW">Generali/Utmost WW</option>
                <option value="Utmost">Utmost</option>
              </>
            </select>
          </div>
        </div>

        <div className="flex flex-row">
          <div className="w-1/5 p-2">
            <label
              for="forename"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Client Forename
            </label>
            <input
              type="text"
              id="forename"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="surname"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Client Surname
            </label>
            <input
              type="text"
              id="surname"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="bond_platform"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Insurance Client Reference
            </label>
            <input
              type="text"
              id="bond_platform"
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="bond_platform_no"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Insurance Doc No
            </label>
            <input
              type="text"
              id="bond_platform_no"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="w-1/5 p-2">
            <label
              for="initial_total_value"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Initial Total
            </label>
            <input
              type="text"
              id="initial_total_value"
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="currency"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              defaultValue={"£"}
              onChange={() => {
                setCurrency(document.getElementById("currency").value);
              }}
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="£">£</option>
              <option value="$">$</option>
              <option value="€">€</option>
              <option value="CZK">CZK</option>
            </select>
          </div>

          {currency !== "£" ? (
            <div className="w-1/5 p-2">
              <label
                for="exchange_rate"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Exchange Rate
              </label>
              <input
                type="text"
                id="exchange_rate"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          ) : null}
        </div>

        <div className="flex flex-row mt-2">
          <AdvisorDropdown
            label={"Advisor / Member Name"}
            name={"member_name"}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="agency"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Agency
            </label>
            <select
              id="agency"
              name="agency"
              required
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value=""></option>
              <option value="AI">AI</option>
              <option value="AFP">AFP</option>
            </select>
          </div>
        </div>

        <div className="flex flex-row mt-2">
          <IntroducerDropdown
            label={"Introducer"}
            name={"introducer"}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="introducer_split_from_advisor"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Introducer Split From Advisor
            </label>
            <input
              type="text"
              id="introducer_split_from_advisor"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="w-1/5 p-2">
            <label
              for="second_advisor_split_from_advisor"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              2nd Advisor Split From Advisor
            </label>
            <input
              type="text"
              id="second_advisor_split_from_advisor"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <SecondAdvisorDropdown
            label={"2nd Advisor"}
            name={"2nd_advisor"}
            companyID={props.companyID}
          />

          <div className="w-1/5 p-2">
            <label
              for="applied_to"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Applied To
            </label>
            <select
              id="applied_to"
              name="applied_to"
              className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value=""></option>
              <option value="Whole Fee">Whole Fee</option>
              <option value="Advisor Fee Only">Advisor Fee Only</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}
