import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState } from "react";
import CountryDropdown from "../../Components/CountryDropdown";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";

export default function Test() {
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});
  const [compliance, setCompliance] = useState();
  const [finance, setFinance] = useState();

  const queryClient = useQueryClient();

  const countries = CountryDropdown();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Company added");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // loading companies
  const { data: companies, isLoading } = useQuery({
    queryFn: () => sendGetRequest("companies", {}),
    queryKey: ["companies"],
  });

  // adding companies
  const companyAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return companyAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  const { status: statusAdvisors, data: dataAdvisors } = useGetRequest(
    "advisors/dropdown",
    {}
  );

  if (!isLoading) {
    return (
      <>
        <Header />

        <section class="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div class="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
            <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                <div class="flex items-center flex-1 space-x-4">
                  <h2 class="p-6 text-xl font-bold text-gray-900 dark:text-white">
                    Companies
                  </h2>
                </div>
                <div class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                  <div class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                    <button
                      type="button"
                      onClick={() => setOpenModal(true)}
                      class="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-slate-800"
                    >
                      Add Company
                    </button>
                  </div>
                </div>
              </div>
              <div class="overflow-x-auto p-6">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-4 py-3"></th>
                      <th scope="col" class="px-4 py-3">
                        Company Name
                      </th>
                      <th scope="col" class="px-4 py-3">
                        ID
                      </th>

                      <th scope="col" class="px-4 py-3">
                        Address
                      </th>
                      <th scope="col" class="px-4 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies?.companies.map((company) => (
                      <tr class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <th
                          scope="row"
                          class="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <img
                            src={company.imageURL}
                            alt="iMac Front Image"
                            class="w-auto h-12 mr-3"
                          ></img>
                        </th>
                        <td class="px-4 py-2 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                          {company.companyName}
                        </td>
                        <td class="px-4 py-2">
                          <div class="flex items-center text-sm font-medium text-gray-500">
                            {company.ID}
                          </div>
                        </td>

                        <td class="px-4 py-2 text-sm font-medium text-gray-500">
                          {" "}
                          {company.address1}, {company.town}
                        </td>
                        <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Link to={`/companies/edit/${company.companyID}`}>
                            <Button color="light" size="xs">
                              Edit
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <h2 class="p-2 text-xl font-bold text-gray-900 dark:text-white">
              Add Company
            </h2>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="companyName"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Company Name
                </label>
                <input
                  type="input"
                  id="companyName"
                  name="companyName"
                  onChange={handleChange}
                  value={inputs.companyName || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="id_number"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  ID Number
                </label>
                <input
                  type="input"
                  id="id_number"
                  name="id_number"
                  onChange={handleChange}
                  value={inputs.id_number || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="vatNumber"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  VAT Number
                </label>
                <input
                  type="input"
                  id="vatNumber"
                  name="vatNumber"
                  onChange={handleChange}
                  value={inputs.vatNumber || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="bankConnection"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Bank Connection
                </label>
                <input
                  type="input"
                  id="bankConnection"
                  name="bankConnection"
                  onChange={handleChange}
                  value={inputs.bankConnection || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="bankDetails"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Bank Details
                </label>
                <input
                  type="input"
                  id="bankDetails"
                  name="bankDetails"
                  onChange={handleChange}
                  value={inputs.bankDetails || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="address1"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Address Line 1
                </label>
                <input
                  type="input"
                  id="address1"
                  name="address1"
                  onChange={handleChange}
                  value={inputs.address1 || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="address2"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Address Line 2
                </label>
                <input
                  type="input"
                  id="address2"
                  name="address2"
                  onChange={handleChange}
                  value={inputs.address2 || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="town"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Town
                </label>
                <input
                  type="input"
                  id="town"
                  name="town"
                  onChange={handleChange}
                  value={inputs.town || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="county"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  County
                </label>
                <input
                  type="input"
                  id="county"
                  name="county"
                  onChange={handleChange}
                  value={inputs.county || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="postcode"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Postcode
                </label>
                <input
                  type="input"
                  id="postcode"
                  name="postcode"
                  onChange={handleChange}
                  value={inputs.postcode || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="country"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  onChange={handleChange}
                  value={inputs.country || ""}
                  required
                  className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value=""></option>
                  {countries.map((country) => (
                    <option value={country.code}>{country.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="contractType1"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contract Type
                </label>
                <input
                  type="input"
                  id="contractType1"
                  name="contractType1"
                  value={inputs.contractType1 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractPercentage1"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Percentage Split
                </label>
                <input
                  type="input"
                  id="contractPercentage1"
                  name="contractPercentage1"
                  value={inputs.contractPercentage1 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractFee1"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Minimum Fee
                </label>
                <input
                  type="input"
                  id="contractFee1"
                  name="contractFee1"
                  value={inputs.contractFee1 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="contractType2"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contract Type
                </label>
                <input
                  type="input"
                  id="contractType2"
                  name="contractType2"
                  value={inputs.contractType2 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractPercentage2"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Percentage Split
                </label>
                <input
                  type="input"
                  id="contractPercentage2"
                  name="contractPercentage2"
                  value={inputs.contractPercentage2 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractFee2"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Minimum Fee
                </label>
                <input
                  type="input"
                  id="contractFee2"
                  name="contractFee2"
                  value={inputs.contractFee2 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="contractType3"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contract Type
                </label>
                <input
                  type="input"
                  id="contractType3"
                  name="contractType3"
                  value={inputs.contractType3 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractPercentage3"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Percentage Split
                </label>
                <input
                  type="input"
                  id="contractPercentage3"
                  name="contractPercentage3"
                  value={inputs.contractPercentage3 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractFee3"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Minimum Fee
                </label>
                <input
                  type="input"
                  id="contractFee3"
                  name="contractFee3"
                  value={inputs.contractFee3 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-1/4 p-2">
                <label
                  for="contractType4"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contract Type
                </label>
                <input
                  type="input"
                  id="contractType4"
                  name="contractType4"
                  value={inputs.contractType4 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractPercentage4"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Percentage Split
                </label>
                <input
                  type="input"
                  id="contractPercentage4"
                  name="contractPercentage4"
                  value={inputs.contractPercentage4 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="w-1/4 p-2">
                <label
                  for="contractFee4"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Minimum Fee
                </label>
                <input
                  type="input"
                  id="contractFee4"
                  name="contractFee4"
                  value={inputs.contractFee4 || ""}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>

            <div className="flex flex-row">
              {/* <div className="w-1/4 p-2">
                <label
                  for="splitCompanyEarns"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Split Company Earns
                </label>
                <input
                  type="input"
                  id="splitCompanyEarns"
                  name="splitCompanyEarns"
                  onChange={handleChange}
                  value={inputs.splitCompanyEarns || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div> */}

              <div className="w-1/4 p-2">
                <label
                  for="sharedWithAdvisor"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  2nd Adviser % paid
                </label>
                <input
                  type="input"
                  id="sharedWithAdvisor"
                  name="sharedWithAdvisor"
                  onChange={handleChange}
                  value={inputs.sharedWithAdvisor || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="paidAsIntroducer"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Introducer % paid
                </label>
                <input
                  type="input"
                  id="paidAsIntroducer"
                  name="paidAsIntroducer"
                  onChange={handleChange}
                  value={inputs.paidAsIntroducer || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-1/4 p-2">
                <label
                  for="paidAsTeamLeader"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Team Leader % paid
                </label>
                <input
                  type="input"
                  id="paidAsTeamLeader"
                  name="paidAsTeamLeader"
                  onChange={handleChange}
                  value={inputs.paidAsTeamLeader || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <a
              className="mt-4 w-[20%] inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 "
              href="#"
              onClick={async () => {
                try {
                  await doAddMutation({
                    path: "companies/add",
                    body: {
                      companyName: inputs.companyName,
                      ID: inputs.id_number,
                      vatNumber: inputs.vatNumber,
                      bankConnection: inputs.bankConnection,
                      bankDetails: inputs.bankDetails,
                      address1: inputs.address1,
                      address2: inputs.address2,
                      town: inputs.town,
                      county: inputs.county,
                      postcode: inputs.postcode,
                      country: inputs.country,
                      contractType1: inputs.contractType1,
                      contractPercentage1: inputs.contractPercentage1,
                      contractFee1: inputs.contractFee1,
                      contractType2: inputs.contractType2,
                      contractPercentage2: inputs.contractPercentage2,
                      contractFee2: inputs.contractFee2,
                      contractType3: inputs.contractType3,
                      contractPercentage3: inputs.contractPercentage3,
                      contractFee3: inputs.contractFee3,
                      contractType4: inputs.contractType4,
                      contractPercentage4: inputs.contractPercentage4,
                      contractFee4: inputs.contractFee4,
                      // splitCompanyEarns: inputs.splitCompanyEarns,
                      sharedWithAdvisor: inputs.sharedWithAdvisor,
                      paidAsIntroducer: inputs.paidAsIntroducer,
                      paidAsTeamLeader: inputs.paidAsTeamLeader,
                    },
                  });
                  setOpenModal(false);
                  setInputs({});
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              Add Company
            </a>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
