import { useEffect, useState, React, use } from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import CountryDropdown from "../../../Components/CountryDropdown";
import { Link } from "react-router-dom";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  sendGetRequest,
  sendPostRequest,
  useGetRequest,
} from "../../../Client";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import TeamLeaderDropdown from "./Components/TeamLeaderDropdown";

export default function Details(props) {
  const [compliance, setCompliance] = useState();
  const [finance, setFinance] = useState();
  const [inputs, setInputs] = useState({});

  const [advisorEarnings, setAdvisorEarnings] = useState(true);
  const [serviceFee, setServiceFee] = useState(false);
  const [marketingInitial, setMarketingInitial] = useState("");
  const [marketingOngoing, setMarketingOngoing] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [IDnumber, setIDnumber] = useState("");
  const [VATnumber, setVATnumber] = useState("");
  const [bankConnection, setBankConnection] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [town, setTown] = useState("");
  const [county, setCounty] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [paidAsTeamLeader, setPaidAsTeamLeader] = useState("");
  const [paidAsIntroducer, setPaidAsIntroducer] = useState("");
  const [sharedWithAdvisor, setSharedWithAdvisor] = useState("");
  const [teamLeader, setTeamLeader] = useState("");

  // const [splitCompanyEarns, setSplitCompanyEarns] = useState("");

  const [contractType1, setContractType1] = useState("");
  const [contractType2, setContractType2] = useState("");
  const [contractType3, setContractType3] = useState("");
  const [contractType4, setContractType4] = useState("");
  const [contractPercentage1, setContractPercentage1] = useState("");
  const [contractPercentage2, setContractPercentage2] = useState("");
  const [contractPercentage3, setContractPercentage3] = useState("");
  const [contractPercentage4, setContractPercentage4] = useState("");
  const [contractFee1, setContractFee1] = useState("");
  const [contractFee2, setContractFee2] = useState("");
  const [contractFee3, setContractFee3] = useState("");
  const [contractFee4, setContractFee4] = useState("");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { companyID } = useParams();

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Company Updated");
  };

  // loading strategies
  const { data, isLoading } = useQuery({
    queryFn: () => sendGetRequest("companies/" + companyID, {}),
    queryKey: ["details"],
  });


  useEffect(() => {

    setCompanyName(data?.details?.[0].companyName);
    setIDnumber(data?.details?.[0].ID);
    setVATnumber(data?.details?.[0].vatNumber);
    setBankConnection(data?.details?.[0].bankConnection);
    setBankDetails(data?.details?.[0].bankDetails);
    setAddress1(data?.details?.[0].address1);
    setAddress2(data?.details?.[0].address2);
    setTown(data?.details?.[0].town);
    setCounty(data?.details?.[0].county);
    setPostcode(data?.details?.[0].postcode);
    setCountry(data?.details?.[0].country);
    props.setDetailsCompanyName(data?.details?.[0].companyName);
    props.setDetailsCompanyLogo(data?.details?.[0].imageURL);
    setPaidAsTeamLeader(data?.details?.[0].paidAsTeamLeader);
    setPaidAsIntroducer(data?.details?.[0].paidAsIntroducer);
    setSharedWithAdvisor(data?.details?.[0].sharedWithAdvisor);
    setTeamLeader(data?.details?.[0].teamLeader);
    // setSplitCompanyEarns(data?.details?.[0].splitCompanyEarns);
    setContractType1(data?.contracts?.[0].contractName);
    setContractPercentage1(data?.contracts?.[0].contractPercentage);
    setContractFee1(data?.contracts?.[0].minimumFee);
    setContractType2(data?.contracts?.[1].contractName);
    setContractPercentage2(data?.contracts?.[1].contractPercentage);
    setContractFee2(data?.contracts?.[1].minimumFee);
    setContractType3(data?.contracts?.[2].contractName);
    setContractPercentage3(data?.contracts?.[2].contractPercentage);
    setContractFee3(data?.contracts?.[2].minimumFee);
    setContractType4(data?.contracts?.[3].contractName);
    setContractPercentage4(data?.contracts?.[3].contractPercentage);
    setContractFee4(data?.contracts?.[3].minimumFee);

    setAdvisorEarnings(data?.details?.[0].splitFromAdvisor ? 1 : 0);
    setServiceFee(data?.details?.[0].serviceFee ? 1 : 0);
    setMarketingInitial(data?.details?.[0].initial);
    setMarketingOngoing(data?.details?.[0].ongoing);
  }, [data]);

  const countries = CountryDropdown();

  const handleSubmit = () => {
    if (advisorEarnings === 1 && marketingInitial > 99) {
      alert("The initial % cannot be more than 99%");
      return;
    }

    if (advisorEarnings === 1 && marketingOngoing > 99) {
      alert("The ongoing % cannot be more than 99%");
      return;
    }

    const requestBody = {
      companyName: companyName,
      IDnumber: IDnumber,
      VATnumber: VATnumber,
      bankConnection: bankConnection,
      bankDetails: bankDetails,
      address1: address1,
      address2: address2,
      town: town,
      county: county,
      postcode: postcode,
      country: country,
      // splitCompanyEarns: splitCompanyEarns,
      sharedWithAdvisor: sharedWithAdvisor,
      paidAsIntroducer: paidAsIntroducer,
      paidAsTeamLeader: paidAsTeamLeader,
      teamLeader: teamLeader ? teamLeader : 0,
      contractType1: contractType1,
      contractPercentage1: contractPercentage1,
      contractFee1: contractFee1,
      contractType2: contractType2,
      contractPercentage2: contractPercentage2,
      contractFee2: contractFee2,
      contractType3: contractType3,
      contractPercentage3: contractPercentage3,
      contractFee3: contractFee3,
      contractType4: contractType4,
      contractPercentage4: contractPercentage4,
      contractFee4: contractFee4,
      splitFromAdvisor: advisorEarnings ? 1 : 0,
      serviceFee: serviceFee ? 1 : 0,
      initial: marketingInitial,
      ongoing: marketingOngoing,
    };

    const res = sendPostRequest("company/update/" + companyID, requestBody);

    navigate(`/companies/edit/${companyID}`, {
      replace: true,
      state: { added: 1 },
    });

    updatedNotyf();
  };

  if (!isLoading) {
    return (
      <>
        <div className="flex flex-col p-4 border-2 border-slate-100 rounded-xl">
          <div>
            <h2 className="text-xl font-semibold leading-7 text-gray-900 mt-2 p-3">
              Company Details
            </h2>
          </div>
          <div className="flex flex-row">
            <div className="w-1/4 p-2 mt-4">
              <label
                for="company"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Company Name
              </label>
              <input
                type="input"
                id="company"
                value={companyName ? companyName : ""}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="id_number"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                ID Number
              </label>
              <input
                type="input"
                id="id_number"
                value={IDnumber ? IDnumber : ""}
                onChange={(e) => {
                  setIDnumber(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/4 p-2">
              <label
                for="vat_number"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                VAT Number
              </label>
              <input
                type="input"
                id="vat_number"
                value={VATnumber ? VATnumber : ""}
                onChange={(e) => {
                  setVATnumber(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/4 p-2">
              <label
                for="bank_connection"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Bank Connection
              </label>
              <input
                type="input"
                id="bank_connection"
                value={bankConnection ? bankConnection : ""}
                onChange={(e) => {
                  setBankConnection(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/4 p-2">
              <label
                for="bank_details"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Bank Details
              </label>
              <input
                type="input"
                id="bank_details"
                value={bankDetails ? bankDetails : ""}
                onChange={(e) => {
                  setBankDetails(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="address1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Address Line 1
              </label>
              <input
                type="input"
                id="address1"
                value={address1 ? address1 : ""}
                onChange={(e) => {
                  setAddress1(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="address2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Address Line 2
              </label>
              <input
                type="input"
                id="address2"
                value={address2 ? address2 : ""}
                onChange={(e) => {
                  setAddress2(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="town"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Town
              </label>
              <input
                type="input"
                id="town"
                value={town ? town : ""}
                onChange={(e) => {
                  setTown(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="county"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                County
              </label>
              <input
                type="input"
                id="county"
                value={county ? county : ""}
                onChange={(e) => {
                  setCounty(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="postcode"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Postcode
              </label>
              <input
                type="input"
                id="postcode"
                value={postcode ? postcode : ""}
                onChange={(e) => {
                  setPostcode(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="country"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Country
              </label>
              <select
                id="country"
                name="country"
                value={inputs.country || ""}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                {/* <option value=""></option> */}
                {country ? (
                  <option selected value={country}>
                    {country}
                  </option>
                ) : null}
                {countries.map((country) => (
                  <option value={country.code}>{country.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="contractType1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contract Type
              </label>
              <input
                type="input"
                id="contractType1"
                value={contractType1 ? contractType1 : ""}
                onChange={(e) => {
                  setContractType1(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractPercentage1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Percentage Split
              </label>
              <input
                type="input"
                id="contractPercentage1"
                value={contractPercentage1 ? contractPercentage1 : ""}
                onChange={(e) => {
                  setContractPercentage1(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractFee1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Minimum Fee
              </label>
              <input
                type="input"
                id="contractFee1"
                value={contractFee1 ? contractFee1 : ""}
                onChange={(e) => {
                  setContractFee1(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="contractType2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contract Type
              </label>
              <input
                type="input"
                id="contractType2"
                value={contractType2 ? contractType2 : ""}
                onChange={(e) => {
                  setContractType2(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractPercentage2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Percentage Split
              </label>
              <input
                type="input"
                id="contractPercentage2"
                value={contractPercentage2 ? contractPercentage2 : ""}
                onChange={(e) => {
                  setContractPercentage2(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractFee2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Minimum Fee
              </label>
              <input
                type="input"
                id="contractFee2"
                value={contractFee2 ? contractFee2 : ""}
                onChange={(e) => {
                  setContractFee2(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="contractType3"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contract Type
              </label>
              <input
                type="input"
                id="contractType3"
                value={contractType3 ? contractType3 : ""}
                onChange={(e) => {
                  setContractType3(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractPercentage3"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Percentage Split
              </label>
              <input
                type="input"
                id="contractPercentage3"
                value={contractPercentage3 ? contractPercentage3 : ""}
                onChange={(e) => {
                  setContractPercentage3(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractFee3"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Minimum Fee
              </label>
              <input
                type="input"
                id="contractFee3"
                value={contractFee3 ? contractFee3 : ""}
                onChange={(e) => {
                  setContractFee3(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="contractType4"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contract Type
              </label>
              <input
                type="input"
                id="contractType4"
                value={contractType4 ? contractType4 : ""}
                onChange={(e) => {
                  setContractType4(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractPercentage4"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Percentage Split
              </label>
              <input
                type="input"
                id="contractPercentage4"
                value={contractPercentage4 ? contractPercentage4 : ""}
                onChange={(e) => {
                  setContractPercentage4(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-2">
              <label
                for="contractFee4"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Minimum Fee
              </label>
              <input
                type="input"
                id="contractFee4"
                value={contractFee4 ? contractFee4 : ""}
                onChange={(e) => {
                  setContractFee4(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="sharedWithAdvisor"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                2nd Adviser % paid
              </label>
              <input
                type="input"
                id="sharedWithAdvisor"
                value={sharedWithAdvisor ? sharedWithAdvisor : ""}
                onChange={(e) => {
                  setSharedWithAdvisor(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/4 p-2">
              <label
                for="paidAsIntroducer"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Introducer % paid
              </label>
              <input
                type="input"
                id="paidAsIntroducer"
                value={paidAsIntroducer ? paidAsIntroducer : ""}
                onChange={(e) => {
                  setPaidAsIntroducer(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/4 p-2">
              <label
                for="paidAsTeamLeader"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Team Leader % paid
              </label>
              <input
                type="input"
                id="paidAsTeamLeader"
                value={paidAsTeamLeader ? paidAsTeamLeader : ""}
                onChange={(e) => {
                  setPaidAsTeamLeader(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <TeamLeaderDropdown
              label={"Team Leader"}
              name={"teamLeader"}
              setTeamLeader={setTeamLeader}
              companyID={companyID}
              teamLeader={teamLeader}
            />
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="paidAsTeamLeader"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white underline mt-4"
              >
                Marketing Split
              </label>
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <div className="w-1/6 p-1">
              <label
                for="advisor"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                % Split from Advisor
              </label>
              <input
                type="checkbox"
                id="advisorEarnings"
                checked={advisorEarnings}
                onChange={() => {
                  setAdvisorEarnings(!advisorEarnings);
                  setServiceFee(!serviceFee);
                }}
                className="align-center h-4"
              />
            </div>

            <div className="w-1/6 p-1">
              <label
                for="introducer"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Service Fee
              </label>
              <input
                type="checkbox"
                id="serviceFee"
                checked={serviceFee}
                onChange={() => {
                  setAdvisorEarnings(!advisorEarnings);
                  setServiceFee(!serviceFee);
                }}
                className="align-center h-4"
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-2">
              <label
                for="marketingInitial"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Initial
              </label>
              <input
                type="input"
                id="marketingInitial"
                value={marketingInitial ? marketingInitial : ""}
                onChange={(e) => {
                  setMarketingInitial(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/4 p-2">
              <label
                for="marketingOngoing"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Ongoing
              </label>
              <input
                type="input"
                id="marketingOngoing"
                value={marketingOngoing ? marketingOngoing : ""}
                onChange={(e) => {
                  setMarketingOngoing(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="mt-4 ml-20 flex justify-end mr-4 mb-4">
            <Button onClick={() => handleSubmit()} color="dark" size="sm">
              Edit
            </Button>
          </div>
        </div>
      </>
    );
  }
}
